<script lang="ts" setup>
// countries
const { countries, isPending: isPendingCountries } = useApiCountries()
</script>
<template>
  <div class="space-y-4">
    <FormKit
      type="text"
      name="name"
      label="Name"
      outer-class="w-full mt-6"
      validation="required"
    />

    <FormKit
      type="listbox"
      name="countryId"
      label="Country"
      outer-class="w-full"
      :options="countries"
      :loading="isPendingCountries"
      track-by="id"
      label-by="name"
      validation="required"
      :searchable="true"
      data-1p-ignore
    />

    <FormKit
      type="text"
      name="tradingName"
      label="Trading name"
      outer-class="w-full"
    />

    <div class="grid grid-cols-2 gap-4 md:grid-cols-2">
      <FormKit
        type="text"
        name="businessNumber"
        label="Business number"
        outer-class="w-full"
      />

      <FormKit
        type="text"
        name="taxFileNumber"
        label="Tax file number"
        outer-class="w-full"
      />
    </div>

    <FormKit type="text" name="branch" label="Branch" outer-class="w-full" />

    <FormKit type="text" name="brands" label="Brands" outer-class="w-full" />

    <FormKit
      type="textarea"
      auto-height
      name="notes"
      label="Notes"
      outer-class="w-full"
    />
  </div>
</template>
